
import BrowserUnsupportedModal from './BrowserUnsupportedModal.vue'

import { Component, Vue, Watch, Model } from 'vue-property-decorator'

import Store from '../../store/index'

import { getModule } from 'vuex-module-decorators'
import User from '../../store/modules/user'
import Translations from '../../store/modules/translations'
import Transcripts from '@/store/modules/transcripts'
import Recording from '@/store/modules/recording'
import TermsAndConditions from './TermsAndConditions.vue'

import api from '@/api/main'
import { COLOR_VARIANT, TRANSCRIPTS_AGE } from '@/constants'
import ApiException from '@/exceptions/ApiException'

import { USER_DETAILS_FORM } from '@/config/userDetailsForm'

// create tyepsafe accessor using getModule to access state of the User and Configuration from the store.
const user = getModule(User, Store)
const recording = getModule(Recording, Store)
const translations = getModule(Translations)
const transcripts = getModule(Transcripts)

// Keys for accessing the translation json file.
const HELLO_KEY = 'homeHelloText'
const HELP_KEY = 'homeNeedHelpText'
const THANK_KEY = 'homeThankYouText'
const SAMPLES_KEY = 'homeSamplesText'
const SPARE_TIME_KEY = 'homeSpareTimeText'
const NEXT_KEY = 'homeNextText'

const UI_TEXT_TOAST_ERROR_TITLE = 'toastErrorErrorTitle'
const UI_TEXT_TOAST_ERROR_USER_CREATE = 'toastErrorUserCreate'

@Component({
  components: {
    BrowserUnsupportedModal,
    TermsAndConditions
  }
})
export default class WelcomeComponent extends Vue {
    @Model('change', { type: Boolean }) readonly checked!: boolean
    isNextButtonDisabled = true

    showTermsAndConditionsModal = false

    isBrowserSupported = true // Default browser being supported to true, check is done on mount

    // Define local data to store the translated text.
    helloText = translations.getTranslations(HELLO_KEY)
    helpText = translations.getTranslations(HELP_KEY)
    thanksText = translations.getTranslations(THANK_KEY)
    samplesText = translations.getTranslations(SAMPLES_KEY)
    spareTimeText = translations.getTranslations(SPARE_TIME_KEY)
    nextText = translations.getTranslations(NEXT_KEY)

    get userAgreedTCs () {
      return user.getHasAgreedTCs
    }

    @Watch('userAgreedTCs')
    // eslint-disable-next-line
    onUserAgreedTCsChanged (val: boolean, _: any) {
      this.isNextButtonDisabled = !val
    }

    mounted () {
      try {
        this.isBrowserSupported = this.$browserDetect.isChrome || this.$browserDetect.isSafari || this.$browserDetect.isEdge || this.$browserDetect.isFirefox
        if (!this.isBrowserSupported) {
          this.$root.$emit('bv::show::modal', 'modal-browser-unsupported')
        }
      } catch (e) {
        console.log(e)
      }
    }

    nextRoute () {
      if (USER_DETAILS_FORM) {
        this.$router.push('/user-details')
      } else {
        this.submitUserDetails()
      }
    }

    async submitUserDetails () {
      this.isNextButtonDisabled = true

      const detailsToSubmit = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        age_group: '',
        gender: '',
        accent: '',
        region: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        native_language: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        second_language: ''
      }

      try {
        const response: Record<string, string> = await api.createUser(detailsToSubmit)

        transcripts.setTranscriptionType(TRANSCRIPTS_AGE.DEFAULT)

        user.setUserId(response.user_id)
        user.updateIsValid(true)

        recording.setRecordingId()

        this.$router.push('/audio-check')
      } catch (apiError) {
        let toastMessageKey: string = UI_TEXT_TOAST_ERROR_USER_CREATE
        let toastColorVariant: string = COLOR_VARIANT.DANGER

        if (apiError instanceof ApiException) {
          toastMessageKey = apiError.toastMessage
          toastColorVariant = apiError.toastColor
        }

        this.$bvToast.toast(translations.getTranslations(toastMessageKey), {
          title: translations.getTranslations(UI_TEXT_TOAST_ERROR_TITLE),
          variant: toastColorVariant,
          solid: true
        })
      }
    }
}
