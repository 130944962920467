import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faHeadphones, faCheckCircle, faMicrophone, faTimesCircle, faSadCry, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CircularCountDownTimer from 'vue-circular-count-down-timer'
import browserDetect from 'vue-browser-detect-plugin'

import BootstrapVue from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/assets/css/global.css'

library.add(faTimes)
library.add(faHeadphones)
library.add(faInfoCircle)
library.add(faCheckCircle)
library.add(faTimesCircle)
library.add(faMicrophone)
library.add(faSadCry)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(CircularCountDownTimer)
Vue.use(browserDetect)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
