
import { Component, Vue } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'
import Translations from '@/store/modules/translations'

const translations = getModule(Translations) // Store accessor.

// Keys for accessing the translation json file.
const BUTTON_KEY = 'homeCloseText'
const TITLE_KEY = 'errorBrowserUnsupportedTitle'
const CONTENT_KEY = 'errorBrowserUnsupportedText'

@Component
export default class UserNoActionTimeOutModal extends Vue {
    buttonText = translations.getTranslations(BUTTON_KEY)
    titleText = translations.getTranslations(TITLE_KEY)
    contentText = translations.getTranslations(CONTENT_KEY)
}
