
import { Component, Vue, Watch } from 'vue-property-decorator'

import { getModule } from 'vuex-module-decorators'
import User from '@/store/modules/user'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

import { TCS_CHECKBOXES } from '@/config/tcsCheckboxes'

const translations = getModule(Translations) // Store accessor.
const user = getModule(User, Store)

// Keys for accessing the translation json file.
const TERMS_AND_CONDITIONS_KEY = 'homeTermsAndConditions'
const REQ_CHECKBOXES_KEY = 'homeRequiredCheckboxes'

@Component
export default class TermsAndConditions extends Vue {
  tcsTitleText = translations.getTranslations(TERMS_AND_CONDITIONS_KEY)
  reqCheckboxesText = translations.getTranslations(REQ_CHECKBOXES_KEY)
  checkboxes = TCS_CHECKBOXES
  selectedCheckboxes: string[] = []

  @Watch('selectedCheckboxes')
  onAgreedTCSchanged (selectedCheckboxes: string[]) {
    const requiredCheckboxesValues = this.checkboxes.reduce((acc: string[], el) => {
      return el.required ? [...acc, el.value] : acc
    }, [])

    const selectedRequiredCheckboxes = requiredCheckboxesValues.filter((value) => {
      return selectedCheckboxes.includes(value)
    })

    const accepted = requiredCheckboxesValues.length === selectedRequiredCheckboxes.length
    user.updateAgreedTCs(accepted)
  }
}
