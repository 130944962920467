
import Component, { mixins } from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import Translations from '@/store/modules/translations'
import { getModule } from 'vuex-module-decorators'
import { USER_DETAILS_FORM } from '@/config/userDetailsForm'

const translations = getModule(Translations) // Store accessor.

// Keys for accessing the translation json file.
const WELCOME_KEY = 'progressListWelcome'
const DETAILS_KEY = 'progressListYourDetails'
const AUDIO_CHECK_KEY = 'progressListBackgroundAudioCheck'
const RECORDINGS_KEY = 'progressListRecordings'
const DONE_KEY = 'progressListDone'

type ViewNames = 'welcome' | 'userDetails' | 'audioCheck' | 'recording' | 'done'

type ViewProps = {
  name: ViewNames;
  label: string;
}

export const VIEWS: ViewProps[] = [
  {
    name: 'welcome',
    label: translations.getTranslations(WELCOME_KEY)
  },
  {
    name: 'userDetails',
    label: translations.getTranslations(DETAILS_KEY)
  },
  {
    name: 'audioCheck',
    label: translations.getTranslations(AUDIO_CHECK_KEY)
  },
  {
    name: 'recording',
    label: translations.getTranslations(RECORDINGS_KEY)
  },
  {
    name: 'done',
    label: translations.getTranslations(DONE_KEY)
  }
]

if (!USER_DETAILS_FORM) VIEWS.splice(1, 1)

// Define the props by using Vue's canonical way.
const ProgressListProps = Vue.extend({
  props: {
    index: String
  }
})

@Component
export default class ProgressList extends mixins(ProgressListProps, Vue) {
  views = VIEWS
}
