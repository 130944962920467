import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import Store from '../index'

import { TRANSCRIPTS_AGE } from '@/constants'

import { TRANSCRIPTS } from '@/config/transcripts'

const TRANSCRIPTS_LIST: TranscriptsConfigProps = { default: [], kids: [] }

const processTranscripts = (transcriptsArray: TranscriptProps[]) => {
  const result = transcriptsArray.reduce((acc, el) => {
    const transcripts: TranscriptProps[] = []
    if (el.repeats) {
      for (let i = 0; i < el.repeats; i++) {
        transcripts.push({
          transcript: el.transcript,
          type: el.type
        })
      }
    } else {
      transcripts.push(el)
    }

    return [...acc, ...transcripts]
  }, [] as TranscriptProps[])

  return result
}

TRANSCRIPTS_LIST.default = processTranscripts(TRANSCRIPTS.default)
TRANSCRIPTS_LIST.kids = processTranscripts(TRANSCRIPTS.kids)

@Module({
  dynamic: true,
  store: Store,
  name: 'transcripts',
  namespaced: true
})
export default class Transcripts extends VuexModule {
  public transcriptions = TRANSCRIPTS_LIST
  private transcriptionType: string = TRANSCRIPTS_AGE.DEFAULT

  get getTranscription () {
    return (index: number) => {
      return this.transcriptions[this.transcriptionType][index]
    }
  }

  get getTotalTranscriptType () {
    return (age: string, type: string) => {
      let totalTranscription = 0

      if (Object.keys(this.transcriptions).includes(age)) {
        totalTranscription = this.transcriptions[age].reduce((accumulator, transcript) => accumulator + (transcript.type === type ? 1 : 0), 0)
      }

      return totalTranscription
    }
  }

  get getTranscriptionsCount (): number {
    let totalTranscription = 0

    if (this.transcriptions && Object.keys(this.transcriptions).includes(this.transcriptionType)) {
      totalTranscription = this.transcriptions[this.transcriptionType].length
    }

    return totalTranscription
  }

  @Mutation
  public setTranscriptionType (type: string): void {
    this.transcriptionType = type
  }
}
