/* eslint-disable @typescript-eslint/camelcase */
/**
 * Store for the Recordings.
 */

import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { RECORDING_STATE } from '@/constants'

import { v4 as uuidv4 } from 'uuid'

export interface RecordingState {
  quietTimeout: boolean;
  status: number;
  recordingId: string;
  audioContext: AudioContext|null;
}

@Module({
  name: 'recording',
  namespaced: true
})
export default class Recording extends VuexModule implements RecordingState {
  // recordings state defined below
  quietTimeout = false
  status = RECORDING_STATE.IDLE
  recordingId = ''
  audioContext: AudioContext|null = null

  // ***************
  // GETTERS
  // ***************
  get getQuietTimeout (): boolean {
    return this.quietTimeout
  }

  get getStatus (): number {
    return this.status
  }

  get getRecordingId (): string {
    return this.recordingId
  }

  get getAudioContext (): AudioContext|null {
    return this.audioContext
  }

  // ***************
  // MUTATIONS
  // ***************
  @Mutation
  public setQuietTimeout (value: boolean): void {
    this.quietTimeout = value
  }

  @Mutation
  public setRecordingId (value: string | undefined = undefined): void {
    this.recordingId = value || uuidv4()
  }

  @Mutation
  public setAudioContext (value: AudioContext): void {
    this.audioContext = value
  }

  @Mutation
  public reset (): void {
    this.quietTimeout = false
    this.status = RECORDING_STATE.IDLE
    this.recordingId = ''
    this.audioContext = null
  }

  @Mutation
  public updateStatus (value: number) {
    this.status = value
  }
}
