/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/**
 * Store for the Current Part.
 */
import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

export interface RecordingPartState {
  index: number;
  type: string;
  transcript: string;
  fileName: string;
  recordingBlob: Blob;
  recording: any[];
}

 @Module({
   name: 'recordingPart',
   namespaced: true
 })
export default class RecordingPart extends VuexModule implements RecordingPartState {
  index = 0
  type = ''
  transcript = 'Hello Sky'
  fileName = ''
  recordingBlob = new Blob([''], { type: 'audio/wav' })
  recording = []

  // ***************
  // GETTERS
  // ***************
  get getIndex (): number {
    return this.index
  }

  get getType (): string {
    return this.type
  }

  get getTranscript (): string {
    return this.transcript
  }

  get getFileName (): string {
    return this.fileName
  }

  get getRecording (): any[] {
    return this.recording
  }

  get getRecordingBlob (): Blob {
    return this.recordingBlob
  }

   // ***************
   // MUTATIONS
   // ***************
   // Note: mutation below is for testing purposes only - don't use in implementation
   @Mutation
  public testSetIndex (): void {
    this.index = -1
  }

  @Mutation
   public incrementIndex (): void {
     this.index++
   }

  @Mutation
  public setType (value: string): void {
    this.type = value
  }

  @Mutation
  public setTranscript (value: string): void {
    this.transcript = value
  }

  @Mutation
  public setFileName (value: Record<string, string>): void {
    this.fileName = `${value.userId}_${value.recordingId}_${this.index}`
  }

  @Mutation
  public appendData (value: []): void {
    this.recording = this.recording.concat(value)
  }

  @Mutation
  public setRecordingBlob (value: Blob): void {
    this.recordingBlob = value
  }

  @Mutation
  public reset (): void {
    this.index = 0
    this.type = ''
    this.transcript = ''
    this.fileName = ''
    this.recording = []
  }

  @Mutation
  public resetRecordingData (): void {
    this.recording = []
  }
}
