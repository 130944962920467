import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Welcome from '../views/WelcomeView.vue'

import { getModule } from 'vuex-module-decorators'

import User from '@/store/modules/user'
import Recording from '@/store/modules/recording'
import RecordingPart from '@/store/modules/recordingPart'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/user-details',
    name: 'User Details',
    component: () =>
      import('@/views/UserDetailsView.vue')
  },
  {
    path: '/audio-check',
    name: 'Audio Check',
    component: () =>
      import('@/views/AudioCheckView.vue')
  },
  {
    path: '/recordings',
    name: 'Recordings',
    component: () =>
      import('@/views/RecordingsView.vue')
  },
  {
    path: '/done',
    name: 'Done',
    component: () =>
      import('@/views/RecordingsDoneView.vue')
  },
  {
    path: '/return',
    name: 'Return',
    component: () =>
      import('@/views/ReturnFromSessionView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const translations = getModule(Translations)
  const websiteTitle = translations.getTranslations('headerWebsiteTitle')
  document.title = websiteTitle

  let nextRoute: string|null = null
  // If no name, means we reloaded the page
  if (!from.name) {
    nextRoute = 'Welcome'

    const user = getModule(User, Store)
    /*
      As we are saving the user, recording and recordingPart in localStorage,
      We need to be careful how the pages gets accessed, as we don't want people to be able to reload on Done and land there
      we will check the recording.recording_id and recording.status.
      If we have a recording_id, and the status is   BEGIN: 0, COUNTING_DOWN: 1, RECORDING: 2, PLAYBACK: 3, or TIME_OUT: 4
      We were on Recordings, and then we need to ask them whether they want to carry on, and redirect them to the ReloadCheckSessionCarryOn page
    */
    if (to.name === 'Recordings' || to.name === 'Return' || to.name === 'Welcome') {
      // Then we check if they were in the middle of a session, so if recording.recordingId is not empty/null, same for recordingPart.index
      const recording = getModule(Recording, Store)
      const recordingPart = getModule(RecordingPart, Store)

      if (recording.getRecordingId !== '' && recordingPart.getIndex > 0) {
        nextRoute = 'Return'
      } else {
        // Then we reset every thing, as we want to start from a clean stack
        user.reset()
        recording.reset()
        recordingPart.reset()
      }
    } else {
      // Reinitiliase
      user.updateAgreedTCs(false)
    }
  } else {
    // We land in the else if we navigated through the history or just followed the expected flow
    if (to.name !== 'Welcome') {
      nextRoute = 'Welcome'

      const user = getModule(User, Store)
      if (to.name === 'User Details' && user.getHasAgreedTCs) {
        nextRoute = null
      } else if (user.getIsValid) {
        nextRoute = null
      }
    } else {
      if (to.name === 'Welcome') {
        const user = getModule(User, Store)
        user.updateAgreedTCs(false)
      }
      nextRoute = null
    }
  }

  if (nextRoute && to.name !== nextRoute) {
    next({
      name: nextRoute
    })
  } else {
    next()
  }
})

export default router
